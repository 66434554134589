import React from "react";

export default function RecommendOther(props) {
  const notThisTitle = props.title;
  const allRecommendations = require("../pages/recommendations.json");

  const anotherOne = allRecommendations.filter(function (el) {
    return el.title !== notThisTitle;
  });
  const randomIndexy = Math.floor(Math.random() * anotherOne.length);

  const recommendPlaceInfo = anotherOne[randomIndexy];

  if (!recommendPlaceInfo) return null;

  return (
    <div className="other-node-wrap-sidebar">
      <h4>Other Recommendations</h4>

      <div className="other-options">
        <div className="img">
          <a href={recommendPlaceInfo.link}>
            <img
              src={recommendPlaceInfo.img}
              alt={recommendPlaceInfo.tagInfo}
              title={recommendPlaceInfo.tagInfo}
            />
          </a>
        </div>
        <div className="title">
          <a href={recommendPlaceInfo.link}>{recommendPlaceInfo.title}</a>
        </div>
      </div>
    </div>
  );
}
