import React from "react";

export default function RestaurantCard(props) {
  const restarantPlaceInfo = props.data;
  const stringUpMap = (e) => {
    const thisOne = e.trim();
    return `https://www.google.com/maps/place/${thisOne.replace(/\s+/g, "+")}`;
  };

  return (
    <div>
      <div className="top-restaurant-card">
        <div>
          <div className="title">{restarantPlaceInfo.title}</div>
          <div className="address">
            <label>Address: </label>
            {restarantPlaceInfo.address}
          </div>
          <div className="phoneNumber">
            <label>Phone: </label>
            {restarantPlaceInfo.phoneNumber}
          </div>
        </div>
        <div className="linksy">
          <a
            target="_blank"
            title={restarantPlaceInfo.website}
            href={restarantPlaceInfo.website}
          >
            Visit Website
          </a>
          <a
            target="_blank"
            href={stringUpMap(restarantPlaceInfo.address)}
            title={stringUpMap(restarantPlaceInfo.address)}
          >
            View in Maps
          </a>
        </div>
      </div>
    </div>
  );
}
