import React from "react";

import RestaurantCard from "./RestaurantCard";

export default function recommendTop(props) {
  const recommendPlaceInfo = props.data;

  const stringUpMap = (e) => {
    const thisOne = e.trim();
    return `https://www.google.com/maps/place/${thisOne.replace(/\s+/g, "+")}`;
  };

  return (
    <div>
      <section className="top-recommendation-image">
        <img
          src={recommendPlaceInfo.img}
          alt={recommendPlaceInfo.tagInfo}
          title={recommendPlaceInfo.tagInfo}
        />
      </section>

      <div className="top-the-info">
        <div className="top-recommendation-we-like">
          <h3> {recommendPlaceInfo.title} Highlights</h3>
          <ul>
            {recommendPlaceInfo.whatWeLike.map((node, i) => {
              return (
                <li className="promo-we-likey" key={i}>
                  <i className="fa-regular fa-heart" key={i} />
                  {node}
                </li>
              );
            })}
          </ul>
        </div>

        <div className="top-recommendation-card-info">
          <RestaurantCard data={recommendPlaceInfo}></RestaurantCard>
        </div>

        {/* <div className="top-recommendation-card">
          <div>
            <div className="title">{recommendPlaceInfo.title}</div>
            <div className="address">
              <label>Address: </label>
              {recommendPlaceInfo.address}
            </div>
            <div className="phoneNumber">
              <label>Phone #: </label>
              {recommendPlaceInfo.phoneNumber}
            </div>
          </div>
          <div className="linksy">
            <a
              target="_blank"
              title={recommendPlaceInfo.website}
              href={recommendPlaceInfo.website}
            >
              Visit Website
            </a>
            <a
              target="_blank"
              href={stringUpMap(recommendPlaceInfo.address)}
              title={stringUpMap(recommendPlaceInfo.address)}
            >
              View in Maps
            </a>
          </div>
        </div> */}
      </div>
    </div>
  );
}
