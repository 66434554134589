import React from "react";
import Head from "../../components/Head.js";
import Layout from "../../components/Layout.js";
import RightSidebar from "../../components/RightSidebar.js";

import RecommendTop from "../../components/RecommendTop.js";
import RecommendOther from "../../components/RecommendOther.js";

const allRecommendations = require("../recommendations.json");

// mark
const recommendPlaceInfo = allRecommendations[8];

const TestPage = () => {
  return (
    <>
      <Head
        title={`Austin City Tacos Recommends: ` + recommendPlaceInfo.title}
        description={`One of our top recommendations is ${recommendPlaceInfo.title}`}
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>Austin City Tacos Recommends: {recommendPlaceInfo.title}</h1>

              <RecommendTop data={recommendPlaceInfo}></RecommendTop>

              <div className="big-description">
                <p>
                  Is your weird Aunt from Seattle in town for a visit? Are you
                  feeling left out by the taco fusion trend? Then head straight
                  into downtown to the Austin Taco Project. This restaurant is
                  pulling out all the stops: full craft bar, patio, interactive
                  games, and Austin's first Topgolf Swing Suite.
                </p>
                <p>
                  But this restaurant isn't just for instagram posts, the food
                  is amazing. The "Confit Pig" was one of the best choices in
                  weeks, which is a braised pork shoulder with pineapple salsa.
                  Also amazing and fun was the tiny tacos, of which we tried all
                  3: poke, backyard brisket, and tempura avacado. This spot
                  boasts some really unique combos as well. A friend tried the
                  vegetarian P.E.T.A taco, in addition to "Oc-Thai-Pus" taco
                  made with shaved octopus.
                </p>
              </div>
            </div>

            <div className="right-sidebar">
              <RecommendOther title={recommendPlaceInfo.title}></RecommendOther>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TestPage;
