import React from "react";

import RafflePromotion from "./RafflePromotion.js";
import AustinEvents from "./AustinEvents";
// import RightAdsCycle from "./RightAdsCycle";

const RightSidebar = ({ title }) => {
  return (
    <>
      <div className="r-side">
        <div className="email-new-raffle">
          <RafflePromotion></RafflePromotion>
        </div>
        <div className="austin-events">
          <AustinEvents></AustinEvents>
        </div>
        {/* <div className="right-cycles">
          <RightAdsCycle></RightAdsCycle>
        </div> */}
      </div>
    </>
  );
};

export default RightSidebar;
