import React, { Component } from "react";

class RafflePromotion extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const name = localStorage.getItem("tacosignupemail");

    if (name) {
      this.setState({ signedUp: true });
    }
  }

  state = {
    signedUp: false,
  };

  render() {
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const d = new Date();
    let thisMonth = month[d.getMonth()];

    const isSignedUp = this.state.signedUp;

    return (
      <div
        id="raffle-promotion"
        className={`raffle-promotion ${isSignedUp ? "completed" : ""}`}
      >
        <div className="top">
          {isSignedUp ? (
            <>
              <h4>Some free tacos might be in your future.</h4>
              <p>
                Good Luck! <br />
                We are rooting for you!
              </p>
            </>
          ) : (
            <div>
              <h4>What would you do for free Tacos?</h4>
              <p>
                For {thisMonth} only, we are offering a $25 UberEats gift card.
                Winner selected at the end of this month and all we need is a{" "}
                <a href="#special-deal-email">email</a>.
              </p>
              <div className="disclaimer">
                * one entry per person silly goose{" "}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default RafflePromotion;
